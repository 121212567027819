// extracted by mini-css-extract-plugin
export var background_images = "culture-module__background_images___Exo7a";
export var body_background = "#f8f9fa";
export var card = "culture-module__card___eUOeF";
export var chip = "culture-module__chip___b5-ub";
export var container = "culture-module__container___OEkWD";
export var container_culture = "culture-module__container_culture___yx7e+";
export var container_culture__card = "culture-module__container_culture__card___ygpDo";
export var container_culture__container_cards = "culture-module__container_culture__container_cards___RkDPy";
export var container_culture__content_highlight = "culture-module__container_culture__content_highlight___E2dD6";
export var container_culture__content_img = "culture-module__container_culture__content_img___90sOR";
export var container_culture__content_img__img = "culture-module__container_culture__content_img__img___n9YKE";
export var container_culture__follow = "culture-module__container_culture__follow___+4uAu";
export var container_culture__subtitle = "culture-module__container_culture__subtitle___WJqiL";
export var container_culture__text = "culture-module__container_culture__text___aGe+C";
export var container_culture__title = "culture-module__container_culture__title___Ltzs3";
export var content = "culture-module__content___7uME-";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "culture-module__full_img___ps2cv";
export var full_imgSmall = "culture-module__full_img--small___5KQ+f";
export var gray_21 = "culture-module__gray_21___2reQ+";
export var image_container = "culture-module__image_container___8RObc";
export var image_item = "culture-module__image_item___F+B0-";
export var lg = "1200px";
export var logo = "culture-module__logo___HyW0F";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "culture-module__primary___OWaLu";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var title = "culture-module__title___JJGbf";
export var typography_h1 = "culture-module__typography_h1___djcy7";
export var typography_h2 = "culture-module__typography_h2___ar10v";
export var typography_h3 = "culture-module__typography_h3___V6z49";
export var white = "#fff";
export var white_button = "culture-module__white_button___v92WI";
export var xl = "1536px";
export var xxl = "2500px";